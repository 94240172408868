var Flickity = require('flickity');
require('flickity-imagesloaded');

var scrolls = document.querySelectorAll('.grid__container--scroll');

for (var i = 0;  i < scrolls.length; i += 1) {
  var scroll = new Flickity(scrolls[i], {
    imagesLoaded: true,
    cellAlign: 'left',
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    watchCSS: true
  });
  var scrolled = function() {
    this.classList.add('grid__container--scroll--scrolled');
  };
  scrolls[i].addEventListener('touchstart', scrolled);
  scrolls[i].addEventListener('mousedown', scrolled);
}

//

var sliders = document.querySelectorAll('.grid--slider');

for (var i = 0; i < sliders.length; i += 1) {
  var slider = new Flickity(sliders[i], {
    imagesLoaded: true,
    cellAlign: 'left',
    contain: true,
    watchCSS: true,
    arrowShape: 'M64.2,88.1c-0.6,0-1.2-0.2-1.7-0.7L27,51.8c-0.9-0.9-0.9-2.4,0-3.4l35.6-35.6c0.9-0.9,2.4-0.9,3.4,0s0.9,2.4,0,3.4L32,50.1 L65.9,84c0.9,0.9,0.9,2.4,0,3.4C65.4,87.8,64.8,88.1,64.2,88.1z'
  });
}
