var Flickity = require('flickity');
require('flickity-imagesloaded');

var sliders = document.querySelectorAll('.story-slider');

for (i = 0; i < sliders.length; i += 1) {
  var slider = new Flickity(sliders[i], {
    imagesLoaded: true,
    prevNextButtons: false
  });
}

//

var shifts = document.querySelectorAll('.story-shift');

for (i = 0; i < shifts.length; i += 1) {
  var slider = new Flickity(shifts[i], {
    imagesLoaded: true,
    watchCSS: true,
    arrowShape: 'M64.2,88.1c-0.6,0-1.2-0.2-1.7-0.7L27,51.8c-0.9-0.9-0.9-2.4,0-3.4l35.6-35.6c0.9-0.9,2.4-0.9,3.4,0s0.9,2.4,0,3.4L32,50.1 L65.9,84c0.9,0.9,0.9,2.4,0,3.4C65.4,87.8,64.8,88.1,64.2,88.1z'
  });
}

//

var shift = function(element) {

  var self = {};

  self.element = element;
  self.container = self.element.parentNode.parentNode;
  self.items = self.element.querySelectorAll('.story-shift__item');
  self.prev = self.container.querySelector('.story-shift-nav--prev');
  self.next = self.container.querySelector('.story-shift-nav--next');
  self.index = 0;
  self.total = self.items.length - 1;

  self.gotoPrev = function(e) {
    if (self.index - 1 >= 0) {
      self.index -= 1;
      self.change();
    }
    e.preventDefault();
  };

  self.gotoNext = function(e) {
    if (self.index + 1 <= self.total) {
      self.index += 1;
      self.change();
    }
    e.preventDefault();
  };

  self.reset = function() {
    for (var i = 0; i <= self.total; i += 1) {
      self.items[i].classList.remove('story-shift__item--active');
    }
  };

  self.set = function() {
    self.items[self.index].classList.add('story-shift__item--active');
  };

  self.shift = function() {
    self.element.style.transform = 'translateX(' + -356 * self.index + 'px)';
    self.element.style['-webkit-transform'] = 'translateX(' + -356 * self.index + 'px)';
    self.element.style['-ms-transform'] = 'translateX(' + -356 * self.index + 'px)';
  };

  self.updateNav = function() {
    if (self.index === 0) {
      self.prev.classList.add('story-shift-nav--disabled');
    } else {
      self.prev.classList.remove('story-shift-nav--disabled');
    }
    if (self.index === self.total) {
      self.next.classList.add('story-shift-nav--disabled');
    } else {
      self.next.classList.remove('story-shift-nav--disabled');
    }
  };

  self.change = function() {
    self.reset();
    self.set();
    self.shift();
    self.updateNav();
  };

  self.listenNav = function(e) {
    document.addEventListener('keydown', self.keydown);
  };

  self.listenNavStop = function(e) {
    document.removeEventListener('keydown', self.keydown);
  };

  self.keydown = function(e) {
    if (e.keyCode === 37) {
      e.preventDefault();
      self.gotoPrev(e);
    }
    if (e.keyCode === 39) {
      e.preventDefault();
      self.gotoNext(e);
    }
  };

  self.init = function() {
    self.change();
    self.prev.addEventListener('click', self.gotoPrev);
    self.next.addEventListener('click', self.gotoNext);
    self.container.addEventListener('focus', self.listenNav);
    self.container.addEventListener('blur', self.listenNavStop);
  };

  return self;

};

for (i = 0; i < shifts.length; i += 1) {
  var myShift = shift(shifts[i]);
  myShift.init();
}
