var toggleButtons = document.querySelectorAll('.secnav__toggle');

if (toggleButtons.length) {
  var toggleList = document.querySelector('.secnav__list');

  
    var mediaQueryList = window.matchMedia("(max-width: 1025px)");
    mediaQueryList.addListener(WidthChange);
    WidthChange(mediaQueryList);
  

  function WidthChange(e) {
    if (e.matches) {
      if (toggleButtons[0].parentNode.parentNode.classList.contains('secnav--active')) {
        toggleButtons[0].setAttribute('aria-expanded', true);
        toggleList.setAttribute('aria-hidden', false);
      } else {
        toggleButtons[0].setAttribute('aria-expanded', false);
        toggleList.setAttribute('aria-hidden', true);
      }

    } else {
      toggleButtons[0].removeAttribute('aria-expanded');
      toggleList.removeAttribute('aria-hidden');
    }
  }

  var toggle = function () {

    this
      .parentNode
      .parentNode
      .classList
      .toggle('secnav--active');

    if (this.parentNode.parentNode.classList.contains('secnav--active')) {
      this.setAttribute('aria-expanded', true);
      this
        .nextElementSibling
        .setAttribute('aria-hidden', false);
    } else {
      this.setAttribute('aria-expanded', false);
      this
        .nextElementSibling
        .setAttribute('aria-hidden', true);
    }
  }

  for (var i = 0; i < toggleButtons.length; i += 1) {
    toggleButtons[i].addEventListener('click', toggle);

  }
}
