// svg polyfill for better xlink support
var svg4everybody = require('svg4everybody');
svg4everybody();

// object-fit polyfill
var objectFitImages = require('object-fit-images');
objectFitImages();




require('what-input');

require('./util/_flickity-fix');

// automatically require all modules
require('./modules/_accordion.js');require('./modules/_alert.js');require('./modules/_animate.js');require('./modules/_carousel.js');require('./modules/_dropdown.js');require('./modules/_form.js');require('./modules/_grid.js');require('./modules/_hero.js');require('./modules/_image.js');require('./modules/_menu.js');require('./modules/_modal.js');require('./modules/_nav.js');require('./modules/_search.js');require('./modules/_secnav.js');require('./modules/_story.js');require('./modules/_subnav.js');require('./modules/_table.js');require('./modules/_tabs.js');require('./modules/_testimonial.js');require('./modules/_timeline.js');require('./modules/_topbar.js');require('./modules/_video.js');
