var close = function(e) {
  var alert = this.parentNode.parentNode;
  alert.parentNode.removeChild(alert);
  document.getElementById("hero-control").focus();
  e.stopPropagation();
};

var targets = document.querySelectorAll('.notification__close');

for (var i = 0; i < targets.length; i += 1) {
  targets[i].addEventListener('click', close);
}
