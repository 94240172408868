var getIndex = function(node) {
  var i = 0;
  while ((node = node.previousSibling) !== null) {
    if (node.nodeType === 1) {
      i += 1;
    }
  }
  return i;
};

var setNav = function(timeline, index, links, items) {
  for (var i = 0; i < links.length; i += 1) {
    links[i].classList.remove('timeline__nav__link--active');
  }
  timeline.querySelector('.timeline__nav__link:nth-child(' + index + ')').classList.add('timeline__nav__link--active');
};

var setItem = function(timeline, index, links, items) {
  for (var i = 0; i < items.length; i += 1) {
    items[i].classList.remove('timeline__item--active');
  }
  timeline.querySelector('.timeline__item:nth-child(' + index + ')').classList.add('timeline__item--active');
};

var setHeight = function(slider) {
  var flkty = Flickity.data(slider);
  flkty.resize();
};

var toggle = function(e) {
  var slider = this.parentNode.parentNode.parentNode.parentNode.parentNode;
  var timeline = this.parentNode.parentNode;
  var index = getIndex(this) + 1;
  var links = timeline.querySelectorAll('.timeline__nav__link');
  var items = timeline.querySelectorAll('.timeline__item');
  setNav(timeline, index, links, items);
  setItem(timeline, index, links, items);
  setHeight(slider);
  e.preventDefault();
};

var nav = document.querySelectorAll('.timeline__nav__link');

for (var i = 0; i < nav.length; i += 1) {
  nav[i].addEventListener('click', toggle);
}

//

var Flickity = require('flickity');
require('flickity-imagesloaded');

var sliders = document.querySelectorAll('.timeline-slider');

for (var i = 0; i < sliders.length; i += 1) {
  var slider = new Flickity(sliders[i], {
    imagesLoaded: true,
    pageDots: false,
    accessibility: false,
    arrowShape: {
      x0: 25,
      x1: 75, y1: 50,
      x2: 75, y2: 40,
      x3: 35
    }
  });
}
